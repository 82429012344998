import { Link, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout, { PageLayoutBackground } from '../../components/common/page-layout/page-layout';
import SectionIntended from '../../components/templates/section-intended/section-intended';

import ServicesStyles from './_scss/services.module.scss';

const ServicesPage = () => {
    const intl = useIntl();

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.services.title' })}
            description={intl.formatMessage({ id: 'pages.services.description' })}
            pageBackground={PageLayoutBackground.Dark}
        >
            <SectionIntended>
                <div className={ServicesStyles.servicesHeader}>
                    <h3 className="color-white">LIEFERUNG</h3>
                </div>
                <div className={ServicesStyles.servicesGrid}>
                    <div className={ServicesStyles.servicesSection}>
                        <p className="color-white">
                            Die auf den Produktseiten genannten Preise enthalten die gesetzliche Mehrwertsteuer und
                            sonstige Preisbestandteile.
                        </p>
                        <p className="color-white">
                            Unsere Produkte @Cycle und @Mirror liefern wir innerhalb von Deutschland und Österreich. Wir
                            liefern grundsätzlich nicht auf Inseln.
                        </p>
                        <p>&nbsp;</p>
                        <p className="color-white">
                            Für mögliche Ausnahmen und Zusatzkosten kontaktiere uns bitte vor deiner Bestellung über
                            unser Kontaktformular oder telefonisch unter{' '}
                            <span className="no-wrap">+49 (0) 2234 - 9997-300</span>.
                        </p>
                    </div>

                    <div className={ServicesStyles.servicesSection}>
                        <p className="color-white">
                            In den angegebenen Preisen sind die Lieferkosten bereits enthalten, sofern es sich um eine
                            „kostenlose Standardlieferung“ per Spedition oder per Paketdienstleister handelt.
                        </p>
                        <p className="color-white">
                            Bei einer „kostenlosen Standardlieferung“ per Spedition erfolgt die Anlieferung der Ware
                            frei Bordsteinkante, also bis zur der Lieferadresse nächstgelegenen öffentlichen
                            Bordsteinkante. Für eine „Lieferung zum Aufstellort“ berechnen wir innerhalb von Deutschland
                            und Österreich pauschal 95 Euro brutto pro Bestellung. Die bestellte Ware wird dabei vom
                            Lieferanten an dem vom Käufer bestimmten Ort der Nutzung aufgestellt und installiert (z.B.
                            im Wohnzimmer). Der Horizon@Mirror wird ausschließlich auf den optional erhältlichen
                            Standfuß installiert. Eine Wandmontage wird nicht von unserem Speditionspartner angeboten
                            und kann daher nur durch den Käufer auf eigenes Risiko erfolgen. Eine „Lieferung zum
                            Aufstellort“ ist innerhalb von Deutschland und Österreich möglich.
                        </p>
                    </div>
                </div>
            </SectionIntended>

            <div className={ServicesStyles.servicesTeaser}>
                <SectionIntended>
                    <h3 className="color-white">GARANTIE</h3>
                    <p>
                        Es gilt die Version der Garantiebedingungen für unsere Horizon@ Produkte, die du bei deiner
                        Bestellbestätigung erhalten hast, sowie die gesetzliche Gewährleistung. Unsere vollständigen
                        Garantiebedingungen kannst du{' '}
                        <a href="/downloads/Garantiekarte@horizon.pdf" target="warranty_card">
                            hier
                        </a>{' '}
                        downloaden.
                    </p>
                </SectionIntended>
            </div>

            <div className={ServicesStyles.servicesSectionSupport}>
                <SectionIntended>
                    <div className={ServicesStyles.servicesHeader}>
                        <h3 className="color-white">TECHNISCHER KUNDENSUPPORT / ERSATZTEILE</h3>
                    </div>

                    <div className={ServicesStyles.servicesGrid}>
                        <div className={ServicesStyles.servicesSection}>
                            <p className="color-white">
                                Du hast technische Fragen zu deinem @Mirror oder @Cycle? Du benötigst Unterstützung bei
                                der Installation deines @Produktes?
                            </p>
                            <p className="color-white">
                                Am besten schaust du erstmal in unsere häufig gestellten Fragen – oft findest du dort
                                schon die passende Antwort:
                            </p>
                            <p className="color-white">
                                <Link to="/service/faq-cycle" className="color-white">
                                    FAQ @CYCLE
                                </Link>{' '}
                                <Link to="/service/faq-mirror" className="color-white">
                                    FAQ @MIRROR
                                </Link>
                            </p>
                        </div>

                        <div className={ServicesStyles.servicesSection}>
                            <p className="color-white">
                                Wenn du einen persönlichen Rat bevorzugst, sind wir innerhalb unserer Öffnungszeiten
                                jederzeit für dich erreichbar. Wann und wie du uns erreichst, findest du unter{' '}
                                <Link to="/contact">Kontakt</Link>.
                            </p>
                        </div>
                    </div>
                </SectionIntended>
            </div>

            <div className={[ServicesStyles.servicesTeaser, ServicesStyles.bottom].join(' ')}>
                <SectionIntended>
                    <p className="color-white bold">
                        Erreichbarkeit: Montag bis Freitag von 8.00 bis 20.00 Uhr. Samstag von 10.00 bis 14.00 Uhr.
                    </p>
                </SectionIntended>
            </div>
        </PageLayout>
    );
};

export default ServicesPage;
